<template>
  <div id="confirmLeave">
    <Confirm
      :value="value"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="handleInput"
    >
      <template #title>Reassignment Confirmation</template>

      <template #default>
        <div v-if="isWorkspaceAvailable" class="q-py-md">
          <p>Please review the reassignment details below and confirm:</p>

          <div class="popupData">
            <div class="q-ml-md">
              <p class="q-mt-md"><span>Current Assignee: </span>{{ name }}</p>
              <p><span>Total No. of Workflows: </span>{{ totalRequest }}</p>
              <p>
                <span>Total No. of Existing Requests: </span>{{ totalTickets }}
              </p>
              <p><span>Upcoming Requests: </span>Enabled</p>
            </div>
            <div class="row q-ml-md">
              <p class="col-6"><span>From : </span>{{ fromDate }}</p>
              <p class="col-6"><span>To : </span>{{ toDate }}</p>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton is-flat label="Cancel" class="q-mr-sm" @click="handleNo" />
        <BaseButton label="Confirm" @click="handleYes" />
      </template>
    </Confirm>
  </div>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmLeave",
  components: { Confirm },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      fromDate: "",
      toDate: "",
      totalRequest: "",
      totalTickets: "",
    };
  },
  computed: {
    isWorkspaceAvailable() {
      console.log(this.workspace);

      return this.workspace !== null && typeof this.workspace === "object";
    },
  },
  watch: {
    workspace: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.name = newVal.userName;
          this.fromDate = newVal.fromDate;
          this.toDate = newVal.toDate;
          this.totalRequest = newVal.totalRequest;
          this.totalTickets = newVal.totalTickets;
        }
      },
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    handleNo() {
      this.$emit("input", false);
      console.log(this.workspace);
    },
    handleYes() {
      this.$emit("input", false);
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  margin-right: 1px;
}
.popupData {
  background-color: #eee;
  border-radius: 15px;
  padding-top: 1px;
}
</style>
