<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @info="handleInfo"
  >
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "FormEntryGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSelect(formEntryId) {
      this.$emit("select", formEntryId);
    },

    handleEdit(formEntryId) {
      this.$emit("edit", formEntryId);
    },

    handleDelete(formEntryId) {
      this.$emit("delete", formEntryId);
    },

    handleRestore(formEntryId) {
      this.$emit("restore", formEntryId);
    },

    handleMore(formEntryId) {
      this.$emit("more", formEntryId);
    },

    handleInfo(formEntryId) {
      this.$emit("info", formEntryId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
