<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>Delete Leave Entry?</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to delete the
          <b> "Entry #{{ formEntryId }}" </b>?. You can always restore the
          deleted leave entry from the trash page.
        </p>
      </div>
    </template>

    <template #footer>
      <div class="displayflex">
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="red"
          @click="handleNo"
        />
        <BaseButton label="yes" color="red" @click="handleYes" />
      </div>
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
// import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "ConfirmDeleteLeaveEntry",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    formEntryId: {
      type: Number,
      default: 0,
    },

    totalItems: {
      type: Number,
      default: 0,
    },

    mode: {
      type: String,
      default: "",
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("delete", { isDeleted: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.displayflex {
  display: flex;
}
</style>
